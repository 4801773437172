import {
  makeStyles,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  withStyles,
} from '@material-ui/core';
import React from 'react';

import LoadingBar from '../LoadingBar';

const useStyles = makeStyles({
  tableHeading: {
    fontWeight: 'bold',
  },
});

const ThresholdLeaderboard = (props: ThresholdLeaderboardProps) => {
  const classes = useStyles();

  if (!props.rows) {
    return <LoadingBar type="table" />;
  }

  const rowComponents = props.rows
    .sort(sortByTotalDescending)
    .map(mapToRowComponent(props.total ?? 0))

  const handicapProps = props.rows
    .filter((row) => row.handicap)
    .map((row) => ({ id: row.id, name: row.name, handicap: row.handicap! }));

  return (
    <React.Fragment>
      <Typography
        style={{ padding: '0 0 0 5px' }}
        component="h2"
        variant="h6"
        color="primary"
        gutterBottom
      >
        Leaderboard
      </Typography>
      <TableContainer component={Paper}>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell className={classes.tableHeading}>Name</TableCell>
              <TableCell className={classes.tableHeading} align="right">
                MEPs
              </TableCell>
              <TableCell className={classes.tableHeading} align="right">
                Avg
              </TableCell>
              <TableCell className={classes.tableHeading} align="right">
                Safety
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>{rowComponents}</TableBody>
        </Table>
      </TableContainer>

      <HandicapTable {...{ handicaps: handicapProps }} />
    </React.Fragment>
  );
};

const HandicapTable = (props: HandicapProps) => {
  const classes = useStyles();

  if (!props.handicaps.length) {
    return null;
  }

  return (
    <React.Fragment>
      <TableContainer component={Paper} style={{ paddingTop: '10px' }}>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell className={classes.tableHeading}>Name</TableCell>
              <TableCell className={classes.tableHeading} align="right">
                Handicap
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {props.handicaps.map((handicap) => (
              <StyledTableRow key={handicap.id}>
                <TableCell component="th" scope="row">
                  {handicap.name}
                </TableCell>
                <TableCell align="right">
                  <code>{handicap.handicap}</code>
                </TableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </React.Fragment>
  );
};

export default ThresholdLeaderboard;

export type ThresholdLeaderboardProps = { rows?: Row[]; total?: number };
type Row = {
  id: string;
  name: string;
  handicap?: string;
  total: number;
  average: number;
};

type HandicapProps = {
  handicaps: { id: string; name: string; handicap: string }[];
};

const sortByTotalDescending = (a: Row, b: Row) => b.total - a.total;

const mapToRowComponent = (total: number) => (row: Row) => {
  const distanceToSafey = Math.max(total - row.total, 0);

  return (
    <StyledTableRow key={row.id}>
      <TableCell component="th" scope="row">
        {row.name}
        {row.handicap ? '*' : null}
      </TableCell>
      <TableCell align="right">{Math.floor(row.total).toLocaleString()}</TableCell>
      <TableCell align="right">{Math.floor(row.average).toLocaleString()}</TableCell>
      <TableCell align="right">
        {distanceToSafey ? Math.floor(distanceToSafey).toLocaleString() : '-'}
      </TableCell>
    </StyledTableRow>
  );
};

const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);
