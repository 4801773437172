import { format, startOfDay } from 'date-fns';
import React, { FunctionComponent } from 'react';
import {
  CartesianGrid,
  Label,
  Legend,
  Line,
  LineChart,
  ReferenceArea,
  ReferenceLine,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';

import { stringToColour } from '../../util/string-to-colour';
import LoadingBar from '../LoadingBar';
import { getRequiredPace, getRequiredTotal } from './helpers';

const ThresholdLineChart: FunctionComponent<ThresholdChartProps> = (
  props: ThresholdChartProps,
) => {
  if (!props.visible) return null;

  if (!props.competitors || !props.startDate || !props.endDate) {
    return <LoadingBar type="chart" />;
  }

  const totalRequired = getRequiredTotal(
    props.round,
    props.startDate,
    props.endDate,
  );
  const paceRequired = getRequiredPace(props.round, props.startDate);
  const series = props.competitors.map(mapToSeries);
  const lines = series.map((line) => (
    <Line
      key={line.id}
      data={line.data}
      dataKey="total"
      name={line.name}
      type="monotoneX"
      strokeWidth="2"
      stroke={stringToColour(line.id)}
      isAnimationActive={false}
    />
  ));

  return (
    <React.Fragment>
      <ResponsiveContainer width="100%" height={500}>
        <LineChart>
          <CartesianGrid strokeDasharray="3 3" vertical={false} />
          <XAxis
            dataKey="timestamp"
            allowDuplicatedCategory={false}
            tickFormatter={(unixTime) => format(unixTime, 'do MMM')}
          />
          <YAxis dataKey="total" type="number" orientation="right" />
          <Tooltip
            labelFormatter={(unixTime) => format(Number(unixTime), 'do MMM')}
          />
          <Legend
            verticalAlign="top"
            layout="horizontal"
            align="left"
            wrapperStyle={{ paddingBottom: '15px' }}
          />

          <ReferenceLine
            y={totalRequired}
            stroke="green"
            opacity={0.25}
            ifOverflow="extendDomain"
            strokeWidth="3"
          >
            <Label
              value={`${totalRequired}🎉`}
              position="insideTopRight"
              opacity="0.5"
              style={{ fontWeight: 'bold' }}
            />
          </ReferenceLine>

          <ReferenceLine
            y={paceRequired}
            stroke="red"
            opacity={0.25}
            ifOverflow="extendDomain"
            strokeWidth="2"
          >
            <Label
              value={`${paceRequired} 💀`}
              position="insideBottomLeft"
              opacity="0.5"
              style={{ fontWeight: 'bold' }}
            />
          </ReferenceLine>

          <ReferenceArea y1={0} y2={paceRequired} fill="red" opacity="0.05" />

          {lines}
        </LineChart>
      </ResponsiveContainer>
    </React.Fragment>
  );
};

const mapToSeries = (competitor: Competitor) => ({
  ...competitor,
  data: competitor.data.map(mapCompetitorData).sort(sortTimeAscending),
});

const mapCompetitorData = (data: Data) => ({
  timestamp: startOfDay(data.date).getTime(),
  total: data.total,
});

const sortTimeAscending = (
  a: { timestamp: number },
  b: { timestamp: number },
) => a.timestamp - b.timestamp;

export default ThresholdLineChart;
export type ThresholdChartProps = {
  visible: boolean;
  startDate?: Date;
  endDate?: Date;
  competitors?: Competitor[];
  round?: 1 | 2 | 3 | 4;
};
type Competitor = {
  id: string;
  name: string;
  data: Data[];
};
type Data = { date: Date; total: number };
