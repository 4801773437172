import { LinearProgress, Typography } from '@material-ui/core';
import React from 'react';

const LoadingBar = (props: { type?: string }) => {
  return (
    <React.Fragment>
      <Typography>Loading{props.type ? ` ${props.type}` : ''}...</Typography>
      <LinearProgress />
    </React.Fragment>
  );
};

export default LoadingBar;
