import { Typography } from '@material-ui/core';
import { format } from 'date-fns';
import React from 'react';

type FooterProps = {
  lastUpdated?: Date;
};

const Footer = (props: FooterProps) => {
  if (!props.lastUpdated) {
    return null;
  }

  return (
    <React.Fragment>
      <footer>
        <Typography variant="body2" color="textSecondary" align="center">
          <span style={{ fontWeight: 600 }}>Accurate at: </span>
          {format(props.lastUpdated, 'do MMM k:mm')}
        </Typography>
      </footer>
    </React.Fragment>
  );
};

export default Footer;
