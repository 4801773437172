import { differenceInDays, startOfDay } from 'date-fns';

const ROUND_MAP = new Map<number, number>([
  [1, 120],
  [2, 140],
  [3, 160],
  [4, 180],
]);

export const getRequiredTotal = (
  round: number = 0,
  startDate: Date,
  endDate: Date,
) => {
  const duration = differenceInDays(endDate, startDate) + 1;
  return getRequiredDailyAverageByRound(round) * duration;
};

export const getRequiredPace = (round: number = 0, startDate: Date) => {
  const daysElapsed =
    differenceInDays(startOfDay(Date.now()), startOfDay(startDate)) + 1;
  return getRequiredDailyAverageByRound(round) * daysElapsed;
};

export const getRequiredDailyAverageByRound = (round: number = 0) =>
  ROUND_MAP.get(round) ?? 0;
