import React from 'react';
import {
  Bar,
  BarChart,
  Cell,
  Label,
  LabelList,
  LabelProps,
  ReferenceArea,
  ReferenceLine,
  ResponsiveContainer,
  YAxis,
} from 'recharts';

import { stringToColour } from '../../util/string-to-colour';
import LoadingBar from '../LoadingBar';
import {
  getRequiredDailyAverageByRound,
  getRequiredPace,
  getRequiredTotal,
} from './helpers';

const ThresholdBarChart = (props: BarChartProps) => {
  if (!props.visible) return null;

  if (!props.competitors || !props.startDate || !props.endDate) {
    return <LoadingBar type="chart" />;
  }

  const totalRequired = getRequiredTotal(
    props.round,
    props.startDate,
    props.endDate,
  );
  const requiredPace = getRequiredPace(props.round, props.startDate);
  const requiredDailyAverage = getRequiredDailyAverageByRound(props.round);
  const thriving =
    requiredPace + requiredDailyAverage <= totalRequired
      ? requiredPace + requiredDailyAverage
      : totalRequired;

  const titanium =
    thriving + requiredDailyAverage <= totalRequired
      ? thriving + requiredDailyAverage
      : Number.MAX_SAFE_INTEGER;

  const silverback =
    titanium + requiredDailyAverage <= totalRequired
      ? titanium + requiredDailyAverage
      : Number.MAX_SAFE_INTEGER;

  const competitors = props.competitors
    .map((c) => capTotal(c, props.round ?? 0, props.startDate!, props.endDate!))
    .filter(filterCompetitorsWithNoActivity);

  const cells = competitors.map(({ id }) => (
    <Cell key={id} fill={stringToColour(id)} />
  ));

  const thrivingLine = competitors.find(
    (c) => c.total >= thriving && c.total < titanium,
  ) ? (
    <ReferenceLine
      y={thriving}
      opacity={1}
      stroke="black"
      strokeDasharray="1 3"
    >
      <Label
        value="Thriving 👉"
        position="insideBottomLeft"
        opacity="0.5"
        style={{ fontWeight: 'bold', fontSize: '6pt' }}
      />
    </ReferenceLine>
  ) : null;

  const titaniumLine = competitors.find((c) => c.total >= titanium) ? (
    <ReferenceLine
      y={titanium}
      opacity={1}
      stroke="black"
      strokeDasharray="1 3"
    >
      <Label
        value="Titanium 👉"
        position="insideBottomLeft"
        opacity="0.5"
        style={{ fontWeight: 'bold', fontSize: '6pt' }}
      />
    </ReferenceLine>
  ) : null;

  const silverbackLine = competitors.find((c) => c.total >= silverback) ? (
    <ReferenceLine
      y={silverback}
      opacity={1}
      stroke="black"
      strokeDasharray="1 3"
    >
      <Label
        value="Silverback 👉"
        position="insideBottomLeft"
        opacity="0.5"
        style={{ fontWeight: 'bold', fontSize: '6pt' }}
      />
    </ReferenceLine>
  ) : null;

  const deathLine =
    requiredPace < totalRequired ? (
      <ReferenceLine
        y={getRequiredPace(props.round, props.startDate)}
        stroke="red"
        opacity={1}
        ifOverflow="extendDomain"
        strokeWidth="2"
      >
        <Label
          value={`${getRequiredPace(props.round, props.startDate)}💀`}
          position="insideBottomLeft"
          opacity="0.5"
          style={{ fontWeight: 'bold', fontSize: '6pt' }}
        />
      </ReferenceLine>
    ) : null;

  return (
    <React.Fragment>
      <ResponsiveContainer width="100%" height={500}>
        <BarChart data={competitors}>
          <YAxis type="number" domain={[0, totalRequired * 1.05]} hide />
          <Bar dataKey="total" isAnimationActive={false}>
            {cells}
            <LabelList
              dataKey="name"
              position="top"
              content={(contentProps) =>
                renderLabel(
                  contentProps,
                  competitors,
                  totalRequired,
                  requiredPace,
                  requiredDailyAverage,
                )
              }
            />
          </Bar>

          <ReferenceLine
            y={totalRequired}
            stroke="green"
            opacity={0.25}
            ifOverflow="extendDomain"
            strokeWidth="3"
          >
            <Label
              value={`${totalRequired}🎉`}
              position="insideTopRight"
              opacity="0.5"
              style={{ fontWeight: 'bold', fontSize: '6pt' }}
            />
          </ReferenceLine>
          <ReferenceArea
            y1={totalRequired}
            y2={7000}
            ifOverflow="visible"
            fill="green"
            opacity="0.1"
          />

          {thrivingLine}
          {titaniumLine}
          {silverbackLine}
          {deathLine}

          <ReferenceArea
            y1={0}
            y2={getRequiredPace(props.round, props.startDate)}
            fill="red"
            opacity="0.1"
          />
        </BarChart>
      </ResponsiveContainer>
    </React.Fragment>
  );
};

const renderLabel = (
  props: LabelProps,
  competitors: Competitor[],
  totalRequired: number,
  currentPace: number,
  requiredDailyPace: number,
) => {
  const { x, y, width, value } = props;
  const radius = 10;

  const competitor = competitors.find((c) => c.name === value)!;
  const abvreviation = (value as string).substring(0, 3).toUpperCase();

  return (
    <g>
      <text
        x={x! + width! / 2}
        y={y! - radius}
        fill={stringToColour(competitor.id)}
        textAnchor="middle"
        dominantBaseline="middle"
      >
        {abvreviation}
        {getEmoji({
          currentTotal: competitor.total,
          requiredTotal: totalRequired,
          requiredTotalPace: currentPace,
          requiredDailyPace: requiredDailyPace,
        })}
      </text>
    </g>
  );
};

export default ThresholdBarChart;

export type BarChartProps = {
  visible: boolean;
  startDate?: Date;
  endDate?: Date;
  competitors?: Competitor[];
  round?: number;
};

type Competitor = {
  id: string;
  name: string;
  total: number;
  dailyAverage: number;
  history: { date: Date; total: number }[];
};

const capTotal = (
  competitor: Competitor,
  round: number,
  startDate: Date,
  endDate: Date,
) => {
  competitor.total = Math.min(
    competitor.total,
    getRequiredTotal(round, startDate, endDate),
  );

  return competitor;
};

const filterCompetitorsWithNoActivity = (competitor: Competitor) =>
  !(competitor.history.length > 1 && competitor.total === 0);

const getEmoji = ({
  currentTotal,
  requiredTotal,
  requiredTotalPace,
  requiredDailyPace,
}: {
  currentTotal: number;
  requiredTotal: number;
  requiredTotalPace: number;
  requiredDailyPace: number;
}) => {
  const thriving = requiredTotalPace + requiredDailyPace;
  const titanium = thriving + requiredDailyPace;
  const silverback = titanium + requiredDailyPace;
  const oneDayBehind = requiredTotalPace - requiredDailyPace;
  const twoDaysBehind = oneDayBehind - requiredDailyPace;
  const threeDaysBehind = twoDaysBehind - requiredDailyPace;

  switch (true) {
    case currentTotal >= requiredTotal:
      return '🏆'; // finished
    case currentTotal >= silverback:
      return '🦍';
    case currentTotal >= titanium:
      return '🦾'; // 1.5 days ahead
    case currentTotal >= thriving:
      return '🚀'; // 1 day ahead
    case currentTotal >= requiredTotalPace:
      return '😏'; // On track
    case currentTotal > oneDayBehind:
      return '😬'; // 1 day behind or less
    case currentTotal > twoDaysBehind:
      return '😰'; // 2 days behind or less
    case currentTotal > threeDaysBehind:
      return '🤦'; // 3 days behind or less
    case currentTotal <= requiredTotalPace:
      return '💀'; // more than 3 days behind.
    default:
      return '💪';
  }
};
