import {
  Container,
  CssBaseline,
  Grid,
  makeStyles,
  Paper,
  Switch,
} from '@material-ui/core';
import ms from 'ms';
import React, { useEffect, useState } from 'react';

import { getCurrentChallenge } from './api/get-current-challenge';
import Footer from './components/Footer';
import Header from './components/Header';
import ThresholdBarChart, {
  BarChartProps,
} from './components/Threshold/BarChart';
import { getRequiredTotal } from './components/Threshold/helpers';
import ThresholdLeaderboard, {
  ThresholdLeaderboardProps,
} from './components/Threshold/Leaderboard';
import ThresholdLineChart, {
  ThresholdChartProps,
} from './components/Threshold/LineChart';

const useStyles = makeStyles((theme) => ({
  content: {
    padding: theme.spacing(8, 0, 6),
  },
  paper: {
    margin: theme.spacing(1, 0, 0),
  },
}));

const App = () => {
  const classes = useStyles();
  const ROUND = 1;

  const [switchLabel, setSwitchLabel] = useState<string>('📈');
  const [showLineChart, setShowLineChart] = useState<boolean>(false);
  const [showBarChart, setShowBarChart] = useState<boolean>(true);
  const [challengeName, setChallengeName] = useState<string>();
  const [lastUpdate, setLastUpdate] = useState<Date>();
  const [thresholdLeaderboardProps, setThresholdLeaderboardProps] = useState<
    ThresholdLeaderboardProps
  >();
  const [thresholdBarChartProps, setThresholdBarChartProps] = useState<
    BarChartProps
  >();
  const [thresholdChartProps, setThresholdChartProps] = useState<
    ThresholdChartProps
  >();

  useEffect(() => {
    async function fetchData() {
      const currentChallenge = await getCurrentChallenge();

      setChallengeName(currentChallenge.name);
      setLastUpdate(currentChallenge.lastUpdate);
      setThresholdChartProps({
        visible: showLineChart,
        round: ROUND,
        startDate: currentChallenge.startDate,
        endDate: currentChallenge.endDate,
        competitors: currentChallenge.leaderboard.map((competitor) => ({
          id: competitor.id,
          name: competitor.name,
          data: competitor.history,
        })),
      });
      setThresholdBarChartProps({
        visible: showBarChart,
        round: ROUND,
        startDate: currentChallenge.startDate,
        endDate: currentChallenge.endDate,
        competitors: currentChallenge.leaderboard.map((competitor) => ({
          id: competitor.id,
          name: competitor.name,
          total: competitor.total,
          dailyAverage: competitor.dailyAverage,
          history: competitor.history,
        })),
      });
      setThresholdLeaderboardProps({
        total: getRequiredTotal(
          ROUND,
          currentChallenge.startDate,
          currentChallenge.endDate,
        ),
        rows: currentChallenge.leaderboard.map((competitor) => ({
          id: competitor.id,
          name: competitor.name,
          total: competitor.total,
          average: competitor.dailyAverage,
          handicap: competitor.handicap,
        })),
      });
    }
    fetchData();

    setInterval(fetchData, ms('30 seconds'));
  }, [showBarChart, showLineChart]);

  return (
    <React.Fragment>
      <CssBaseline />
      <Header
        challengeName={challengeName}
        switchLabel={switchLabel}
        switch={
          <Switch
            checked={showBarChart}
            color="default"
            onChange={(event) => {
              setShowBarChart(!showBarChart);
              setShowLineChart(!showLineChart);
              setSwitchLabel(event.target.checked ? '📈' : '📊');
            }}
          />
        }
      />
      <div className={classes.content}>
        <Container maxWidth="lg" style={{ padding: '3px' }}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Paper>
                <Paper className={classes.paper}>
                  <ThresholdBarChart
                    {...thresholdBarChartProps}
                    visible={showBarChart}
                  />
                </Paper>
              </Paper>
            </Grid>
            <Grid item xs={12}>
              <Paper>
                <Paper className={classes.paper}>
                  <ThresholdLineChart
                    {...thresholdChartProps}
                    visible={showLineChart}
                  />
                </Paper>
              </Paper>
            </Grid>
            <Grid item xs={12} lg={6}>
              <Paper>
                <Paper className={classes.paper}>
                  <ThresholdLeaderboard {...thresholdLeaderboardProps} />
                </Paper>
              </Paper>
            </Grid>
          </Grid>
        </Container>
      </div>
      <Footer lastUpdated={lastUpdate} />
    </React.Fragment>
  );
};

export default App;
