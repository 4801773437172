import {
  AppBar,
  FormControlLabel,
  FormGroup,
  Toolbar,
} from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import React, { FunctionComponent } from 'react';

const Header: FunctionComponent<HeaderProps> = (props: HeaderProps) => {
  return (
    <React.Fragment>
      <AppBar>
        <Toolbar>
          <Typography style={{ flex: 1 }} variant="h6" color="inherit" noWrap>
            {props.challengeName}
          </Typography>
          <FormGroup row>
            <FormControlLabel
              control={props.switch}
              label={props.switchLabel}
            />
          </FormGroup>
        </Toolbar>
      </AppBar>
    </React.Fragment>
  );
};

export default Header;

type HeaderProps = {
  challengeName?: string;
  switch: JSX.Element;
  switchLabel: string;
};
