export const getCurrentChallenge = async (): Promise<Challenge> => {
  const response = await fetch(
    `${process.env.REACT_APP_API_BASE}/api/challenges/current`,
  );

  const json = await response.json();

  return {
    id: json.id,
    name: json.name,
    startDate: new Date(json.startDate),
    endDate: new Date(json.endDate),
    lastUpdate: new Date(json.lastUpdated),
    leaderboard: json.leaderboard.map((person: any) => ({
      id: person.id,
      name: person.name,
      dailyAverage: person.dailyAverage,
      total: person.total,
      handicap: person.handicap,
      history: person.history.map((day: any) => ({
        date: new Date(day.date),
        total: day.total,
      })),
    })),
  };
};

interface Challenge {
  id: string;
  name: string;
  startDate: Date;
  endDate: Date;
  lastUpdate: Date;
  leaderboard: {
    id: string;
    name: string;
    dailyAverage: number;
    total: number;
    handicap?: string;
    history: {
      date: Date;
      total: number;
    }[];
  }[];
}
